const $ = require('jquery')
window.jQuery = $
window.$ = $

const dropdown = () => {
    $(document).ready(function () {

        var btn = document.getElementsByClassName('dropdown-btn');
        var i;

        //if button has at least 1 element
        if(btn[0]) {
            // set first drop down to open
            btn[0].classList.toggle('selected');

            const content = btn[0].nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + 'px';
            }

            for (i = 0; i < btn.length; i++) {
                btn[i].addEventListener('click', function () {
                    this.classList.toggle('selected');

                    const content = this.nextElementSibling;
                    if (content.style.maxHeight) {
                        content.style.maxHeight = null;
                    } else {
                        content.style.maxHeight = content.scrollHeight + 'px';
                    }
                });
            }
        }

    });
};

export default dropdown;


