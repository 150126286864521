// // vendor
// import {$,jQuery} from 'jquery';
// // export for others scripts to use
// window.$ = $;
// window.jQuery = jQuery;

// components
import bootstrapMenu from '../../components/bootstrap-menu/bootstrap-menu.js'
import dropdown from "../../components/dropdown-text/dropdown-text";
import initSlider from "../../components/slider/slider";
import fadeOnScroll from "../../components/fade-in-on-scroll/fade";

// modules
import detectIE from "./modules/detect_ie";

// function calls
dropdown();
initSlider();
fadeOnScroll();


if (detectIE() == "11" || detectIE() == "10") {
    $('html').addClass('ie')
    $('.ie-container').addClass('container');
}

