const $ = require('jquery')
window.jQuery = $
window.$ = $

import 'owl.carousel'

const initSlider = () => {
    $(document).ready(function () {
        $('.slider .owl-carousel').owlCarousel({
            loop: true,
            center: true,
            items: 1,

            dots: false,
            smartSpeed: 500,
            navText: ["","<p class='more-featured'>More featured ></p>"],
            responsive: {
                578: {
                    nav: true,
                    items: 1
                }
            }
        })
    })
}

export default initSlider