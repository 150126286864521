const $ = require('jquery')
window.jQuery = $
window.$ = $

import AOS from 'aos';

const fadeOnScroll = () => {
    $(document).ready(function () {
        AOS.init({
            duration: 500,
        });
    });

};

export default fadeOnScroll